import React from 'react';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

const MobileLanguageSelector = ({darkTheme}) => {

  const {language, languages, originalPath} = useI18next();

  const themeClass = darkTheme ? 'nav-menu-dark-theme' : 'nav-menu-white-theme'

  return (
    <div className={`nav-burger-menu-item mobile-lang ${themeClass}`}>
      <label htmlFor="mobile-menu-lang-checkbox">
        {
          language === 'pl' ?
            <StaticImage
              src="../../../images/header/pl-icon.png"
              alt="pl flag"
              placeholder="blurred"
              loading="eager"
              className="language-icon"
            />
            :
            <StaticImage
              src="../../../images/header/en-icon.png"
              alt="en flag"
              placeholder="blurred"
              loading="eager"
              className="language-icon"
            />
        }

        <p>{language}</p>
      </label>
      <input type="checkbox" id="mobile-menu-lang-checkbox" className="menu-checkbox"/>
      <div className="mobile-lang-dropdown">
        {
          languages.map(lang => (
            <div key={lang}>
              {
                lang === 'pl' ?
                  <StaticImage
                    src="../../../images/header/pl-icon.png"
                    alt="pl flag"
                    placeholder="blurred"
                    loading="eager"
                    className="language-icon"
                  />
                  :
                  <StaticImage
                    src="../../../images/header/en-icon.png"
                    alt="en flag"
                    placeholder="blurred"
                    loading="eager"
                    className="language-icon"
                  />
              }
              <Link to={originalPath} language={lang}>
                {lang}
              </Link>
            </div>

          ))
        }
      </div>
    </div>
  );
}

export default MobileLanguageSelector;
