import styled from 'styled-components';
import { Row } from './utils';

export const Input = styled.input`
  font-family: 'Raleway';
  font-weight: 500;
  padding: 1rem 1.5rem;
  width: 418px;
  color: #0f5575;
  font-size: 16px;
  &:focus,
  &:focus-visible {
    outline: none;
  }
  @media only screen and (max-width: 768px) {
    padding: 1rem;
  }
  &.outlined {
    width: 100%;
    color: white;
    border: 2px solid white;
    background-color: transparent;
  }
`;

export const InputWithButton = styled(Row)`
  input,
  div {
    border-radius: 5px;
    border: 2px solid #0f5575;
  }

  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }

  div {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;
