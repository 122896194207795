import React from 'react';
import MENU_CONTENT from './menu_content';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

const DesktopMenu = ({darkTheme}) => {

  const {t} = useTranslation();

  return (
    <div className="nav-menu-items-container">
      {
        MENU_CONTENT.map(menu_item => (
          <div key={t(menu_item.title)} className="nav-menu-item">

            {
              menu_item.sub_content === undefined ? (

                <Link to={menu_item.url}>
                  {t(menu_item.title)}
                </Link>

              ) : (

                <div className="nav-menu-item-inner-container">
                  <Link to={menu_item.url}>
                    {t(menu_item.title)}
                  </Link>

                  {darkTheme ?
                    <StaticImage
                      src="../../../images/header/down-arrow-white.png"
                      alt="Sztrzałka w dół"
                      className="down-arrow-icon-img"
                      placeholder="none"
                      loading="lazy"
                    />
                  :
                    <StaticImage
                      src="../../../images/header/down-arrow.png"
                      alt="Sztrzałka w dół"
                      className="down-arrow-icon-img"
                      placeholder="none"
                      loading="lazy"
                    />
                  }


                  <div className="menu-item-dropdown-content-outer">
                    <div style={{height: '1rem'}}/>

                    <div className="menu-item-dropdown-content">
                      {
                        menu_item.include_in_sub_content &&
                        (
                          <Link key={t(menu_item.title)} to={menu_item.url}>
                            {t(menu_item.title)}
                          </Link>
                        )
                      }

                      {
                        menu_item.sub_content.map(submenu_item => (
                          <Link key={t(submenu_item.title)} to={submenu_item.url}>
                            {t(submenu_item.title)}
                          </Link>
                        ))
                      }
                    </div>
                  </div>
                </div>
              )
            }

          </div>
        ))
      }

      <div className="platform-btn">
        <Link to="/registration">
          {t`menu.platform`}
        </Link>
      </div>

    </div>
  );
}

export default DesktopMenu;
