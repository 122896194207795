import styled, { css } from 'styled-components';
import React from 'react';
import { Row } from './utils';

const solidCss = css`
  box-shadow: 0px 0px 20px rgba(15, 83, 115, 0.2);
  background: #0f5575;
  color: white;
`;

const outlinedSolidWhiteCss = css`
  border: 2px solid white;
  box-shadow: 0px 0px 20px rgba(15, 83, 115, 0.2);
  background: #0f5575;
  color: white;
`;

const outlinedSolidWhiteHoverCss = css`
  color: #0f5575;
  border: 2px solid white;
  background: white;
`;

const solidHoverCss = css`
  color: #0f5575;
  background: white;
`;

const outlinedSolidCss = css`
  color: #0f5575;
  background: transparent;
  border: 2px solid #0f5575;
`;

const outlinedSolidHoverCss = css`
  background: #0f5575;
  color: white;
`;

const emptyCss = css`
  color: #0f5575;
  border: 2px solid transparent;
  > * {
  color: #0f5575 !important;
  }
`;
const emptyHoverCss = css`
  border: 2px solid #0f5575;
  color: #0f5575;
`;

const outlinedCss = css`
  color: #0f5575;
  border: 2px solid #0f5575;
  background: white;
`;

const outlinedHoverCss = css`
  color: white;
  background: #4ebfee;
  border: 2px solid #4ebfee;
`;

export const buttonCss = css`
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 16px;
  height: 23px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 1rem 1.5rem;
  cursor: pointer;
  text-decoration: none;
  ${emptyCss}
  &:hover {
    ${emptyHoverCss}
  }
  &.nohover:hover {
    ${emptyCss}
  }
`

export const Button = styled(Row)`
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 16px;
  height: 23px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 1rem 1.5rem;
  border: 2px solid #0f5575;
  cursor: pointer;

  @media only screen and (max-width: 400px) {
    font-size: 12px;
    padding: 1rem 0.5rem;
  }

  &.solid {
    ${solidCss}
    &:hover {
      ${solidHoverCss}
    }
    &.nohover:not(.disabled):hover {
      ${solidCss}
    }
  }

  &.outlined-solid-white {
    ${outlinedSolidWhiteCss}
    &:hover {
      ${outlinedSolidWhiteHoverCss}
    }
    &.nohover:not(.disabled):hover {
      ${outlinedSolidWhiteCss}
    }
  }

  &.outlined-solid {
    ${outlinedSolidCss}

    &:hover {
      ${outlinedSolidHoverCss}
    }
    &.nohover:not(.disabled):hover {
      ${outlinedSolidCss}
    }

    &.disabled {
      border: 2px solid #dddddd;
      &:hover {
        border: 2px solid #dddddd;
      }
    }
  }

  &.empty {
    ${emptyCss}
    &:hover {
      ${emptyHoverCss}
    }
    &.nohover:hover {
      ${emptyCss}
    }
  }

  &.outlined {
    ${outlinedCss}
    &:hover {
      ${outlinedHoverCss}
    }
    &.nohover:hover {
      ${outlinedCss}
    }
  }
  &.solid-white {
    ${outlinedCss}
    border: white !important;
    &:hover {
      ${outlinedSolidCss}
      color: white !important;
    }
  }

  &.waiting {
    color: white;
  }

  &.success {
    color: rgba(9, 187, 80, 1);
  }

  &.error {
    color: rgba(255, 12, 12, 1);
  }

  &.disabled {
    border: #dddddd;
    background: #dddddd;
    color: white;

    &:hover {
      border: #dddddd;
      background: #dddddd;
      color: white;
    }
  }
`;

export const ButtonsCombined = ({
  values,
  state,
  onStateChange,
  buttonStyle,
  disabled = []
}) => {
  const commonStyles = {
    boxShadow: 'none',
    ...buttonStyle
  };
  return (
    <Row>
      <Button
        style={{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          borderRight: 'none',
          ...commonStyles
        }}
        onClick={() => !disabled[0] && onStateChange(values[0].value)}
        className={`nohover ${state === values[0].value ? 'solid' : 'outlined-solid'
          } ${disabled[0] ? 'disabled' : ''}`}>
        {values[0].label}
      </Button>
      <Button
        style={{
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderLeft: 'none',
          ...commonStyles
        }}
        onClick={() => !disabled[1] && onStateChange(values[1].value)}
        className={`nohover ${state === values[1].value ? 'solid' : 'outlined-solid'
          } ${disabled[1] ? 'disabled' : ''}`}>
        {values[1].label}
      </Button>
    </Row>
  );
};
