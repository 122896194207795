export const donateUsingPOST = (body) => {
  return fetch(
    `${process.env.API_ENDPOINT || '/'}.netlify/functions/api/donate`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
  )
    .then((response) => response.json())
    .then((response) => {
      if (response.link) window.location.href = response.link;
    });
};

export const verifyUsingPOST = (body) => {
  return fetch(
    `${process.env.API_ENDPOINT || '/'}.netlify/functions/api/verify`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
  ).then((response) => response.json());
};
