import React, { useContext } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import {
  Column,
  MediumText,
  NormalText,
  Row,
  SubText,
  Dot,
  Icon
} from '../global/utils';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import { Input } from '../global/input';
import { Button, ButtonsCombined } from '../global/button';
import { StaticImage } from 'gatsby-plugin-image';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import './style/donateModal.scss';

import * as Yup from 'yup';
import { donateUsingPOST, verifyUsingPOST } from '../../services/p24-api';
import { ModalContext } from '../../contexts/ModalContext';

const RadioBox = styled(Row)`
  cursor: pointer;
  border: 1px solid #4ebfee;
  border-radius: 5px;
  padding: 1rem;
  min-height: 38px;
  max-width: 344px;

  margin: 0.5rem 0;
  input[type='radio'] {
    margin-right: 1rem;
  }
`;

const BlueLabel = styled(MediumText)`
  font-weight: 600;
  font-size: 18px;
  color: #5fa5c5;
  text-transform: uppercase;
`;
const RedLabel = styled(MediumText)`
  font-weight: 600;
  font-size: 18px;
  color: #ff0c0c;
  text-transform: uppercase;
`;

const ListHeader = styled(NormalText)`
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 18px;
`;

const CloseButton = styled(Icon)`
  cursor: pointer;
  position: absolute;
  top: 2rem;
  right: 1.5rem;
  z-index: 5;
`;

const RadioInput = styled(Input)`
  font-family: 'Montserrat';
  border: none;
  width: unset;
  padding: 0;
  appearance: textfield;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const DonateDot = styled(Dot)`
  margin-top: 0.5rem;
  display: block !important;
`;

const TransferDetailsLabel = styled(SubText)`
  color: #0f5575;
  min-width: 112px;
`;

const TransferDetailsValue = styled(SubText)`
  font-weight: 700;
  color: #080e14;
`;

const TransferDetailsIcon = styled(Icon)`
  cursor: pointer;
`;

const TransferDetailsRow = styled(Row)`
  margin-top: 1rem;
`;

const DonateType = {
  ONCE: 'once',
  MONTHLY: 'monthly'
};

const ResultColumn = styled(Column)`
  padding: 2rem;
  justify-content: center;
  align-items: center;
  min-width: 376px;
  max-width: 376px;
  text-align: center;

  @media only screen and (max-width: 768px) {
    min-width: unset;
  }
`;

const DonateStep = {
  DONATE_TYPE: 'donate_type',
  CLIENT_DETAILS: 'client_details',
  TRANSFER_DETAILS: 'transfer_details',
  CONFIRMATION: 'confirmation',
  ERROR: 'error',
  LOADING: 'loading',
  REDIRECT: 'redirect'
};

const PaymentMethod = {
  PRZELEWY24: 'przelewy24',
  TRANSFER: 'transfer',
  CREDIT_CARD: 'credit_card'
};

const copyTextToClipBoard = async (value) => {
  try {
    if (typeof navigator !== 'undefined')
      await navigator.clipboard.writeText(value);
  } catch (error) {
    console.error(error);
  }
};

const TransferDetails = ({ translations }) => {
  return (
    <Column>
      <TransferDetailsRow>
        <TransferDetailsLabel>{translations.name}</TransferDetailsLabel>
        <TransferDetailsValue>{translations.nameValue}</TransferDetailsValue>
      </TransferDetailsRow>
      <TransferDetailsRow>
        <TransferDetailsLabel>{translations.title}</TransferDetailsLabel>
        <TransferDetailsValue>{translations.titleValue}</TransferDetailsValue>
        <TransferDetailsIcon
          name="clone"
          onClick={() => copyTextToClipBoard(translations.titleValue)}
        />
      </TransferDetailsRow>
      <TransferDetailsRow>
        <TransferDetailsLabel>{translations.address}</TransferDetailsLabel>
        <TransferDetailsValue>{translations.addressValue}</TransferDetailsValue>
      </TransferDetailsRow>
      <TransferDetailsRow>
        <TransferDetailsLabel>{translations.nip}</TransferDetailsLabel>
        <TransferDetailsValue>{translations.nipValue}</TransferDetailsValue>
      </TransferDetailsRow>
      <TransferDetailsRow>
        <TransferDetailsLabel>{translations.regon}</TransferDetailsLabel>
        <TransferDetailsValue>{translations.regonValue}</TransferDetailsValue>
      </TransferDetailsRow>
      <TransferDetailsRow>
        <TransferDetailsLabel>
          {translations.accountNumber}
        </TransferDetailsLabel>
        <TransferDetailsValue>
          {translations.accountNumberValue}
        </TransferDetailsValue>
        <TransferDetailsIcon
          name="clone"
          onClick={() => copyTextToClipBoard(translations.accountNumberValue)}
        />
      </TransferDetailsRow>
      <TransferDetailsRow>
        <TransferDetailsLabel>{translations.bank}</TransferDetailsLabel>
        <TransferDetailsValue>{translations.bankValue}</TransferDetailsValue>
      </TransferDetailsRow>
    </Column>
  );
};

const DonateModal = ({ paymentRedirect }) => {
  const { language } = useI18next();
  const { isDonateModalOpen, setIsDonateModalOpen } = useContext(ModalContext);
  const { t } = useTranslation();

  const transferDetailsTranslation = {
    name: t('donate.transfer.name'),
    nameValue: t('donate.transfer.nameValue'),
    title: t('donate.transfer.title'),
    titleValue: t('donate.transfer.titleValue'),
    address: t('donate.transfer.address'),
    addressValue: t('donate.transfer.addressValue'),
    nip: t('donate.transfer.nip'),
    nipValue: t('donate.transfer.nipValue'),
    regon: t('donate.transfer.regon'),
    regonValue: t('donate.transfer.regonValue'),
    accountNumber: t('donate.transfer.accountNumber'),
    accountNumberValue: t('donate.transfer.accountNumberValue'),
    bank: t('donate.transfer.bank'),
    bankValue: t('donate.transfer.bankValue')
  };
  const DetailsSchema = Yup.object().shape({
    name: Yup.string(),
    email: Yup.string()
      .email('Adres e-mail jest nieprawidłowy')
      .required('Pole jest wymagane'),
    message: Yup.string()
  });

  const donateValues = [
    {
      label: '50.00 PLN',
      value: 50.0,
      desc: t('donate.values.low')
    },
    {
      label: '100.00 PLN',
      value: 100.0,
      desc: t('donate.values.medium')
    },
    {
      label: '250.00 PLN',
      value: 250.0,
      desc: t('donate.values.high')
    },
    { label: t('donate.values.custom'), value: null, desc: '' }
  ];
  const [paymentMethod, setPaymentMethod] = React.useState(
    PaymentMethod.PRZELEWY24
  );
  const [donateStep, setDonateStep] = React.useState(DonateStep.DONATE_TYPE);
  const [radioOption, setRadioOption] = React.useState(0);
  const [donateType, setDonateType] = React.useState(DonateType.ONCE);
  const [donateValue, setDonateValue] = React.useState(donateValues[0].value);

  React.useEffect(() => {
    //reset default paymentMethod
    setPaymentMethod(
      donateType === DonateType.ONCE
        ? PaymentMethod.PRZELEWY24
        : PaymentMethod.CREDIT_CARD
    );
  }, [donateType]);

  const closeModalWrapper = () => {
    setDonateStep(DonateStep.DONATE_TYPE);
    if (paymentRedirect) {
      updatePath();
    }
    setIsDonateModalOpen(false);
  };

  const updatePath = () => {
    if (typeof window !== `undefined`)
      window.history.pushState({}, document.title, window.location.pathname);
  };

  const verifyPayment = (index = 0) => {
    verifyUsingPOST({ sessionId: paymentRedirect })
      .then(async (body) => {
        if (body.sessionId && [1, 2].includes(body.status))
          setDonateStep(DonateStep.CONFIRMATION);
        else if (body.sessionId && body.status === 1) {
          return setTimeout(() => verifyPayment(index), 3000);
        } else setDonateStep(DonateStep.ERROR);
        try {
          const result = await addToMailchimp(
            body.clientEmail,
            {
              sessionId: body.sessionId,
              name: body.clientName,
              amount: body.amount / 100,
              msg: body.message
            },
            'https://innovationshub.us14.list-manage.com/subscribe/post?u=017bb91f21832f1925030c7b4&amp;id=88b848acc6'
          );

          updatePath();
        } catch (error) {
        }
      })
      .catch((error) => {
        if (index === 3) {
          setDonateStep(DonateStep.ERROR);
          return;
        }
        index++;
        setTimeout(() => verifyPayment(index), 3000);
      });
  };

  React.useEffect(() => {
    if (paymentRedirect) {
      setDonateStep(DonateStep.LOADING);
      verifyPayment();
    }
  }, [paymentRedirect]);

  return (
    <Modal
      isOpen={isDonateModalOpen}
      style={{
        overlay: {
          zIndex: 103,
          background: 'rgba(0,0,0,0.6)',
          overflow: 'auto'
        },
        content: {
          maxWidth: '1028px',
          padding: 0,
          margin: '100px auto',
          height: 'max-content',
          inset: 0
        }
      }}
      onAfterClose={() => (document.body.style.overflow = 'auto')}
      onAfterOpen={() => (document.body.style.overflow = 'hidden')}
      onRequestClose={() => closeModalWrapper()}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      ariaHideApp={false}>
      <CloseButton
        name="close"
        onClick={() => closeModalWrapper()}></CloseButton>
      <Row shouldBreak={true}>
        <Column style={{ borderRight: '1px solid #4EBFEE' }}>
          <StaticImage
            src="../../images/donate/donatePicture.png"
            alt="donate picture"
            placeholder="none"
            loading="eager"
          />
          <Column style={{ padding: '1.5rem 1.5rem 1.5rem 2rem' }}>
            <MediumText>
              {t('donate.info.header1')} <span>{t('donate.info.header2')}</span>{' '}
              {t('donate.info.header3')}.
            </MediumText>
            <ListHeader style={{ margin: '1.5rem 0 1rem 0' }}>
              {t('donate.info.subtitle')}
            </ListHeader>
            <div>
              <Row>
                <DonateDot />
                {t('donate.info.dots.first')}
              </Row>
              <Row>
                <DonateDot />
                {t('donate.info.dots.second')}
              </Row>
              <Row>
                <DonateDot />
                {t('donate.info.dots.third')}
              </Row>
              <Row>
                <DonateDot />
                {t('donate.info.dots.forth')}
              </Row>
              <Row>
                <DonateDot />
                {t('donate.info.dots.fifth')}
              </Row>
              <Row>
                <DonateDot />
                {t('donate.info.dots.sixth')}
              </Row>
            </div>
          </Column>
        </Column>
        {DonateStep.DONATE_TYPE === donateStep && (
          <Column style={{ padding: '2rem', marginTop: '4rem' }}>
            <MediumText style={{ marginBottom: '3rem' }}>
              {t('donate.support.title')}
            </MediumText>
            <ButtonsCombined
              values={[
                {
                  label: t('donate.support.payment.once'),
                  value: DonateType.ONCE
                },
                {
                  label: t('donate.support.payment.monthly'),
                  value: DonateType.MONTHLY
                }
              ]}
              disabled={[false, true]}
              state={donateType}
              onStateChange={(state) => setDonateType(state)}
              buttonStyle={{ width: '140px', marginBottom: '0.5rem' }}
            />
            {donateValues.map((donate, index) => (
              <RadioBox
                className="align-center"
                key={donate.label}
                onClick={() => {
                  setRadioOption(index);
                  setDonateValue(donate.value);
                }}>
                <input
                  readOnly
                  type="radio"
                  id="radio"
                  checked={radioOption === index}
                  name="donateValue"
                />
                <Column>
                  {(donate.desc || radioOption !== index) && (
                    <React.Fragment>
                      <MediumText>{donate.label}</MediumText>
                      <SubText>{donate.desc}</SubText>
                    </React.Fragment>
                  )}
                  {radioOption === index && !donate.desc && (
                    <RadioInput
                      placeholder={t('donate.input.custom-amount')}
                      type="number"
                      step="0.01"
                      onInput={(e) => setDonateValue(+e.target.value)}
                    />
                  )}
                </Column>
              </RadioBox>
            ))}
            <Button
              style={{ marginTop: '1.5rem' }}
              className={`solid ${donateValue ?? 'disabled'} `}
              onClick={() =>
                donateValue && setDonateStep(DonateStep.CLIENT_DETAILS)
              }>
              {t('donate.button.next')}
            </Button>
          </Column>
        )}
        {DonateStep.CONFIRMATION === donateStep && (
          <ResultColumn>
            <Icon
              style={{ width: '100px', height: '100px' }}
              name="blue-tick"
            />
            <BlueLabel style={{ marginTop: '2rem' }}>
              {t('donate.confirmation.accepted')}
            </BlueLabel>
            <MediumText style={{ marginBottom: '1.5rem' }}>
              {t('donate.confirmation.thanks-for-support')}
            </MediumText>
            <NormalText>{t('donate.confirmation.success-text')}</NormalText>
            <Button
              style={{ marginTop: '1.5rem' }}
              className="solid"
              onClick={() => closeModalWrapper()}>
              {t('donate.button.back-to-home')}
            </Button>
          </ResultColumn>
        )}
        {DonateStep.ERROR === donateStep && (
          <ResultColumn>
            <Icon
              style={{ width: '100px', height: '100px' }}
              name="red-cross"
            />
            <RedLabel style={{ marginTop: '2rem' }}>
              {t('donate.confirmation.error')}
            </RedLabel>
            <MediumText style={{ marginBottom: '1.5rem' }}>
              {t('donate.confirmation.something-is-no-yes')}
            </MediumText>
            <NormalText>{t('donate.confirmation.try-again')}</NormalText>
            <Button
              style={{ marginTop: '1.5rem' }}
              className="solid"
              onClick={() => closeModalWrapper()}>
              {t('donate.button.back-to-home')}
            </Button>
          </ResultColumn>
        )}
        {(DonateStep.LOADING === donateStep ||
          DonateStep.REDIRECT === donateStep) && (
            <ResultColumn>
              {DonateStep.LOADING === donateStep && (
                <MediumText>{t('donate.confirmation.loading')}</MediumText>
              )}
              {DonateStep.REDIRECT === donateStep && (
                <MediumText>{t('donate.confirmation.redirect')}</MediumText>
              )}
            </ResultColumn>
          )}
        {DonateStep.CLIENT_DETAILS === donateStep && (
          <Column style={{ padding: '2rem' }}>
            <Button
              className="empty"
              style={{ width: '68px', marginBottom: '24px' }}
              onClick={() => setDonateStep(DonateStep.DONATE_TYPE)}>
              {t('donate.button.back')}
            </Button>
            <Row shouldWrap={true} style={{ flexGrow: 0 }}>
              <BlueLabel style={{ marginRight: '0.5rem' }}>
                {t('donate.support.selected-plan')}
              </BlueLabel>
              <ListHeader style={{ textTransform: 'lowercase' }}>
                <span style={{ textTransform: 'uppercase' }}>
                  {(+donateValue).toFixed(2)} PLN /{' '}
                </span>
                {donateType === DonateType.ONCE
                  ? t('donate.support.payment.once')
                  : t('donate.support.payment.monthly')}
              </ListHeader>
            </Row>
            <BlueLabel style={{ marginTop: '1.5rem', marginBottom: '0.5rem' }}>
              {t('donate.support.payment-method')}
            </BlueLabel>
            <ButtonsCombined
              values={[
                donateType === DonateType.ONCE
                  ? {
                    label: t('donate.support.payment.przelewy24'),
                    value: PaymentMethod.PRZELEWY24
                  }
                  : {
                    label: t('donate.support.payment.credit_card'),
                    value: PaymentMethod.CREDIT_CARD
                  },
                {
                  label: t('donate.support.payment.transfer'),
                  value: PaymentMethod.TRANSFER
                }
              ]}
              state={paymentMethod}
              onStateChange={(state) => setPaymentMethod(state)}
              buttonStyle={{ width: '140px', marginBottom: '0.5rem' }}
            />

            <BlueLabel style={{ marginTop: '1rem' }}>
              {' '}
              {t('donate.support.payment-details')}
            </BlueLabel>
            {paymentMethod === PaymentMethod.TRANSFER && (
              <TransferDetails translations={transferDetailsTranslation} />
            )}
            {paymentMethod !== PaymentMethod.TRANSFER && (
              <Formik
                initialValues={{
                  name: '',
                  email: '',
                  message: ''
                }}
                validationSchema={DetailsSchema}
                onSubmit={async (values, { setErrors }) => {
                  setDonateStep(DonateStep.REDIRECT);
                  donateUsingPOST({
                    name: values.name,
                    email: values.email,
                    amount: +donateValue * 100,
                    message: values.message,
                    donateType,
                    language
                  }).catch(() => {
                    setDonateStep(DonateStep.ERROR);
                  });
                }}>
                {({ errors, touched }) => (
                  <div className="donate-form">
                    {' '}
                    <Form style={{ maxWidth: '376px' }}>
                      <label className="form-item-wrapper">
                        <span>{t('donate.details.fields.name.label')}</span>
                        <Field
                          placeholder={t(
                            'donate.details.fields.name.placeholder'
                          )}
                          className={
                            errors?.name && touched?.name ? 'error' : ''
                          }
                          id="name"
                          name="name"
                        />
                        <ErrorMessage name="name">
                          {(msg) => <div className="error">{msg}</div>}
                        </ErrorMessage>
                      </label>
                      <label className="form-item-wrapper">
                        <span>{t('donate.details.fields.email.label')}</span>
                        <Field
                          id="email"
                          className={
                            errors?.email && touched?.email ? 'error' : ''
                          }
                          name="email"
                          type="email"
                          placeholder={t(
                            'donate.details.fields.email.placeholder'
                          )}
                        />
                        <ErrorMessage name="email">
                          {(msg) => <div className="error">{msg}</div>}
                        </ErrorMessage>
                      </label>
                      <label className="form-item-wrapper">
                        <span>{t('donate.details.fields.message.label')}</span>
                        <Field
                          as="textarea"
                          rows="4"
                          placeholder={t(
                            'donate.details.fields.message.placeholder'
                          )}
                          id="message"
                          name="message"
                        />
                      </label>
                      <p className="rodo">
                        {t('contact.form.rodo.info')}
                        &nbsp;
                        <a href="/rodo">{t('contact.form.rodo.clause')}</a>
                        &nbsp;
                        {t('contact.form.rodo.and')}
                        &nbsp;
                        <a href="/rodo">
                          {t('contact.form.rodo.privacy-policy')}
                        </a>
                        .
                      </p>

                      <Field
                        style={{ marginTop: '1rem' }}
                        name="submit"
                        className="form-button"
                        type="submit"
                        value={t('donate.button.support-us')}
                      />
                    </Form>
                  </div>
                )}
              </Formik>
            )}
          </Column>
        )}
      </Row>
    </Modal>
  );
};

export default DonateModal;
