import React from 'react';
import MENU_CONTENT from './menu_content';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';

const MobileMenu = ({darkTheme}) => {

  const {t} = useTranslation();

  const getCustomCheckboxId = (title) => {
    return 'id__' + title.replace(/ /g, '');
  }

  const getCustomDropdownClassName = (title) => {
    return 'dropdown__' + title.replace(/ /g, '');
  }

  React.useEffect(() => {
    MENU_CONTENT.forEach(menu_item => {
      if (menu_item.sub_content !== undefined) {

        let style = document.createElement('style');
        style.innerHTML = '#' + getCustomCheckboxId(t(menu_item.title)) + ':checked + .'
          + getCustomDropdownClassName(t(menu_item.title)) + ' { display: flex }';

        document.body.appendChild(style);
      }
    })
  }, [t])

  const themeClass = darkTheme ? 'nav-menu-dark-theme' : 'nav-menu-white-theme'

  return (
    <div className={`nav-menu-burger-item-container ${themeClass}`} >
      <div className="container mobile-menu-inner-ctn">
        {
          MENU_CONTENT.map(menu_item => (
              <div key={t(menu_item.title)} className="nav-burger-menu-item">

                {
                  menu_item.sub_content === undefined ? (

                    <Link to={menu_item.url} className="nav-burger-menu-item-hover-effect">
                      {t(menu_item.title)}
                    </Link>

                  ) : (

                    <>
                      <label htmlFor={getCustomCheckboxId(t(menu_item.title))}>
                        <div className="nav-burger-menu-item-hover-effect">
                          <p>
                            {t(menu_item.title)}
                          </p>
                        </div>
                      </label>
                      <input type="checkbox" id={getCustomCheckboxId(t(menu_item.title))} className="menu-checkbox"/>
                      <div className={'nav-menu-burger-item-dropdown ' + getCustomDropdownClassName(t(menu_item.title))}>

                        {
                          menu_item.include_in_sub_content &&
                          (
                            <Link key={t(menu_item.title)} to={menu_item.url}>
                              {t(menu_item.title)}
                            </Link>
                          )
                        }

                        {
                          menu_item.sub_content.map(submenu_item =>  (
                            <Link key={t(submenu_item.title)} to={submenu_item.url}>
                              {t(submenu_item.title)}
                            </Link>
                          ))
                        }
                      </div>
                    </>

                  )
                }

              </div>
            )
          )
        }

        <div className="platform-btn">
          <Link to="/registration">
            {t`menu.platform`}
          </Link>
        </div>
      </div>
    </div>

  );
}

export default MobileMenu;
